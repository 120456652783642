<template>

  <div id="app">
    <router-view/>
  </div>

</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
#app {
  text-align: center;
  width: 100%;
  margin: 0px auto;
}
</style>
